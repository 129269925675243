import {OrganizePackageContent} from './vendorOrderModal/organizePackageContent';
import {Location} from './vendorOrderModal/location';
import {DocumentsTabLoader} from './vendorOrderModal/documentsTabLoader';
import {UploadInvoiceFile} from './vendorOrderModal/uploadInvoiceFile';

import {SelectPostCode} from '../../selectPostCode';

import {UploadInvoice} from '../../uploadInvoice';
import {SelectCity} from '../../selectCity';

import {CustomerAccount} from './vendorOrderModal/customerAccount';
import {OrderTrackingTab} from './vendorOrderModal/orderTrackingTab';
import {Pickup} from "./vendorOrderModal/pickup";
import {AddressManager} from "./vendorOrderModal/addressManager";

import intlTelInput from 'intl-tel-input';
import AdminChangeStatus from "./vendorOrderModal/adminChangeStatus";

import CreateReturn from "./vendorOrderModal/createReturn";
import SendEtd from "../../sendEtd";
import AdminChangeIntegration from "./vendorOrderModal/AdminChangeIntegration";
import AdminSendToAccountant from "./vendorOrderModal/AdminSendToAccountant";
import {Page} from "../../../pages/adminVendorSelect.js";
import $ from "jquery";

export class FormModalOnLoad {

    constructor() {

        this.statusIsUpdated = false;
        this.documentsIsUpdated = false;

        new OrganizePackageContent();

        prettyJson();

        this.events();

        this.modalFix();

        let location = new Location('#address');
        location.reset();

        $('.full_search').removeClass('d-none');
        $('.toggle_full_search').removeClass('d-none');

        new SelectPostCode();

        new UploadInvoice();

        new SelectCity();

        this.documentTab = new DocumentsTabLoader();

        this.orderTrackingTab = new OrderTrackingTab();

        if ($('#customer_account').length > 0) {
            new CustomerAccount();
        }

        new UploadInvoiceFile(this.documentTab);

        new Pickup();

        new AddressManager();

        new AdminChangeStatus();
        new AdminChangeIntegration();
        new AdminSendToAccountant();

        new CreateReturn();

        new SendEtd();

        // Sadece adminde vendor değiştirme tabı
        if ($('#change_vendor-tab').length > 0) {

            $('#change_vendor-tab').on('click', function () {
                new Page();
            });

            $('#js-change-vendor').on('click', function () {

                let newVendorId = $('select[name=changed_vendor]').val();
                let orderId = $(this).data('order_id');

                $.ajax({
                    type: 'post',
                    url: '/admin/order/' + orderId + '/change-vendor',
                    dataType: 'json',
                    cache: false,
                    crossDomain: true,
                    data: {
                        new_vendor_id: newVendorId
                    },
                }).done(function (result) {

                    alert('Vendor değiştirildi...');

                }).fail(function (xhr) {

                    console.log(xhr.responseJSON);

                    alert(xhr.responseJSON.error);
                });
            });
        }

        this.controlIossNo();
    }

    controlIossNo() {
        $('#ioss_no').on('input, keyup', function () {
            let termsOfSale = $('select[name=terms_of_sale]').val();
            if (termsOfSale === 'DAP') {
                let targetText = $('#terms_of_sale_text').text();
                alert(targetText + ' alanı DDP olmalıdır');
            }
        });

        $('select[name=terms_of_sale]').on('change', function () {
            let val = $(this).val();
            let targetVal = $('#ioss_no').val();
            if (targetVal != '' && val != 'DDP') {
                alert('IOSS numarası giriliyse, DDP olmalıdır.');
            }

        })
    }

    events() {

        this.int = this.intTelEvent();

        this.currencyEvent();

        this.modalTabOnChanged();

        let exportType = $('#export_reason_type')

        /**
         * Döküman ise gizlenmesi gereken alanları gizler.
         */
        exportType.off('change').on('change', function () {
            let documentType = 3;
            if ($(this).val() == documentType) {
                $('.js-disable_if_document').hide();
            } else {
                $('.js-disable_if_document').show();
            }
        })

        exportType.trigger('change');

    }

    /**
     * Modal on changed
     */
    modalTabOnChanged() {

        let self = this;

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            let target = $(e.target).attr('href');
            switch (target) {

                case '#documents':
                    if (!self.documentsIsUpdated) {
                        self.documentTab.showDocuments();
                    }
                    self.documentsIsUpdated = true;
                    break;

                case '#status':
                    if (!self.statusIsUpdated) {
                        self.orderTrackingTab.updateTab();
                    }
                    self.statusIsUpdated = true;
                    break;

                default:
            }
        });
    }

    intTelEvent() {
        const input = document.querySelector('.js-int-phone');
        if (input != null) {

            let ini = intlTelInput(input, {
                hiddenInput: "code",
                separateDialCode: true,
                localizedCountries: {
                    af: "Afganistan",
                    ax: "Åland Adalari",
                    al: "Arnavutluk",
                    dz: "Cezayir",
                    as: "Amerikan Samoasi",
                    ad: "Andorra",
                    ao: "Angola",
                    ai: "Anguilla",
                    aq: "Antartika",
                    ag: "Antigua Ve Barbuda",
                    ar: "Arjantin",
                    am: "Ermenistan",
                    aw: "Aruba",
                    au: "Avustralya",
                    at: "Avusturya",
                    az: "Azerbaycan",
                    bs: "Bahamalar",
                    bh: "Bahreyn",
                    bd: "Bangladeş",
                    bb: "Barbados",
                    by: "Belarus",
                    be: "Belçika",
                    bz: "Belize",
                    bj: "Benin",
                    bm: "Bermuda",
                    bt: "Butan",
                    bo: "Bolivya",
                    ba: "Bosna Hersek",
                    bw: "Botsvana",
                    bv: "Bouvet Adasi",
                    br: "Brezilya",
                    io: "Britanya Hint Okyanusu Topraklari",
                    bn: "Brunei",
                    bg: "Bulgaristan",
                    bf: "Burkina Faso",
                    bi: "Burundi",
                    kh: "Kamboçya",
                    cm: "Kamerun",
                    ca: "Kanada",
                    cv: "Cabo Verde",
                    ky: "Cayman Adalari",
                    cf: "Orta Afrika Cumhuriyeti",
                    td: "Çad",
                    cl: "Şili",
                    cn: "Çin",
                    cx: "Christmas Adasi",
                    cc: "Cocos Adalari",
                    co: "Kolombiya",
                    km: "Komorlar",
                    cg: "Kongo",
                    cd: "Kongo Demokratik Cumhuriyeti",
                    ck: "Cook Adalari",
                    cr: "Kosta Rika",
                    ci: "Kotdivuar",
                    hr: "Hirvatistan",
                    cu: "Küba",
                    cy: "Kuzey Kıbrıs Türk Cumhuriyeti",
                    cz: "Çekya",
                    dk: "Danimarka",
                    dj: "Cibuti",
                    dm: "Dominika",
                    do: "Dominik Cumhuriyeti",
                    tl: "Doğu Timor",
                    ec: "Ekvator",
                    eg: "Mısır",
                    sv: "El Salvador",
                    gq: "Ekvator Ginesi",
                    er: "Eritre",
                    ee: "Estonya",
                    et: "Etiyopya",
                    fk: "Falkland Adalari",
                    fo: "Faroe Adalari",
                    fj: "Fiji",
                    fi: "Finlandiya",
                    fr: "Fransa",
                    gf: "Fransiz Guyanasi",
                    pf: "Fransiz Polinezyasi",
                    tf: "Fransiz Güney Topraklari",
                    ga: "Gabon",
                    gm: "Gambiya",
                    ge: "Gürcistan",
                    de: "Almanya",
                    gh: "Gana",
                    gi: "Cebelitarik",
                    gr: "Yunanistan",
                    gl: "Grönland",
                    gd: "Grenada",
                    gp: "Guadeloupe",
                    gu: "Guam",
                    gt: "Guatemala",
                    gg: "Alderney",
                    gn: "Gine",
                    gw: "Gine-bissau",
                    gy: "Guyana",
                    ht: "Haiti",
                    hm: "Heard Adasi Ve Mcdonald Adalari",
                    hn: "Honduras",
                    hk: "Hong Kong",
                    hu: "Macaristan",
                    is: "İzlanda",
                    in: "Hindistan",
                    id: "Endonezya",
                    ir: "İran",
                    iq: "Irak",
                    ie: "İrlanda",
                    il: "İsrail",
                    it: "İtalya",
                    jm: "Jamaika",
                    jp: "Japonya",
                    je: "Jersey",
                    jo: "Ürdün",
                    kz: "Kazakistan",
                    ke: "Kenya",
                    ki: "Kiribati",
                    kp: "Kuzey Kore",
                    kr: "Güney Kore",
                    kw: "Kuveyt",
                    kg: "Kirgizistan",
                    la: "Laos",
                    lv: "Letonya",
                    lb: "Lübnan",
                    ls: "Lesotho",
                    lr: "Liberya",
                    ly: "Libya",
                    li: "Lihtenştayn",
                    lt: "Litvanya",
                    lu: "Lüksemburg",
                    mo: "Makao",
                    mk: "Kuzey Makedonya",
                    mg: "Madagaskar",
                    mw: "Malavi",
                    my: "Malezya",
                    mv: "Maldivler",
                    ml: "Mali",
                    mt: "Malta",
                    im: "Man Adasi",
                    mh: "Marşal Adalari",
                    mq: "Martinik",
                    mr: "Moritanya",
                    mu: "Morityus",
                    yt: "Mayotte",
                    mx: "Meksika",
                    fm: "Mikronezya",
                    md: "Moldova",
                    mc: "Monako",
                    mn: "Moğolistan",
                    me: "Karadağ",
                    ms: "Montserrat",
                    ma: "Fas",
                    mz: "Mozambik",
                    mm: "Myanmar",
                    na: "Namibya",
                    nr: "Nauru",
                    np: "Nepal",
                    bq: "Karayip Hollandasi",
                    nl: "Hollanda",
                    nc: "Yeni Kaledonya",
                    nz: "Yeni Zelanda",
                    ni: "Nikaragua",
                    ne: "Nijer",
                    ng: "Nijerya",
                    nu: "Niue",
                    nf: "Norfolk Adasi",
                    mp: "Kuzey Mariana Adalari",
                    no: "Norveç",
                    om: "Umman",
                    pk: "Pakistan",
                    pw: "Palau",
                    ps: "Filistin",
                    pa: "Panama",
                    pg: "Papua Yeni Gine",
                    py: "Paraguay",
                    pe: "Peru",
                    ph: "Filipinler",
                    pn: "Pitcairn Adalari",
                    pl: "Polonya",
                    pt: "Portekiz",
                    pr: "Porto Riko",
                    qa: "Katar",
                    re: "Réunion",
                    ro: "Romanya",
                    ru: "Rusya",
                    rw: "Ruanda",
                    sh: "Saint Helena",
                    kn: "Saint Kitts Ve Nevis",
                    lc: "Saint Lucia",
                    pm: "Saint Pierre Ve Miquelon",
                    vc: "Saint Vincent Ve Grenadinler",
                    bl: "Saint Barthélemy",
                    mf: "Saint Martin",
                    ws: "Samoa",
                    sm: "San Marino",
                    st: "Sao Tome Ve Prinsipe",
                    sa: "Suudi Arabistan",
                    sn: "Senegal",
                    rs: "Sirbistan",
                    sc: "Seyşeller",
                    sl: "Sierra Leone",
                    sg: "Singapur",
                    sk: "Slovakya",
                    si: "Slovenya",
                    sb: "Solomon Adalari",
                    so: "Somali",
                    za: "Güney Afrika Cumhuriyeti",
                    gs: "Güney Georgia",
                    ss: "Güney Sudan",
                    es: "İspanya",
                    lk: "Sri Lanka",
                    sd: "Sudan",
                    sr: "Surinam",
                    sj: "Svalbard Ve Jan Mayen",
                    sz: "Esvatini",
                    se: "İsveç",
                    ch: "İsviçre",
                    sy: "Suriye",
                    tw: "Tayvan",
                    tj: "Tacikistan",
                    tz: "Tanzanya",
                    th: "Tayland",
                    tg: "Togo",
                    tk: "Tokelau",
                    to: "Tonga",
                    tt: "Trinidad Ve Tobago",
                    tn: "Tunus",
                    tr: "Türkiye",
                    tm: "Türkmenistan",
                    tc: "Turks Ve Caicos Adalari",
                    tv: "Tuvalu",
                    ug: "Uganda",
                    ua: "Ukrayna",
                    ae: "Birleşik Arap Emirlikleri",
                    gb: "Birleşik Krallik",
                    us: "Amerika",
                    um: "Abd Küçük Harici Adalari",
                    uy: "Uruguay",
                    uz: "Özbekistan",
                    vu: "Vanuatu",
                    va: "Vatikan",
                    ve: "Venezuela",
                    vn: "Vietnam",
                    vg: "Britanya Virjin Adalari",
                    vi: "Abd Virjin Adalari",
                    wf: "Wallis Ve Futuna",
                    eh: "Bati Sahra",
                    ye: "Yemen",
                    zm: "Zambiya",
                    zw: "Zimbabve",
                    xk: "Kosova",
                    cw: "Curaçao",
                    sx: "Sint Maarten",
                },
                excludeCountries: []
            });

            input.addEventListener('countrychange', function () {
                let country = ini.getSelectedCountryData();
                $('#phone_code').val(country.dialCode);
            });

            return ini;
        }
    }

    /**
     * Event must fired only in create action
     */
    currencyEvent() {
        let currencySelect = $('select[name=currency]');

        currencySelect.change(function () {
            let value = $(this).find(':selected').attr('data-value');

            // data-value is not set in update form!
            if (typeof value != 'undefined') {
                $('#currency_value').val(value);
            }

            let sign = $(this).find(':selected').data('sign');
            $('.js_currency').text(sign);
        });

        let sign = currencySelect.find(':selected').data('sign');
        $('.js_currency').text(sign);
    }

    /**
     * Fix for :
     * When two bootstrap modals open one after one. Slider disabled when one closed.
     * We make every modal slidable again.
     */
    modalFix() {
        $('.modal').on('hide.bs.modal', function () {
            $('.modal').css({
                'overflow-y': 'auto'
            })
        })
    }

}
