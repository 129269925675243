import $ from "jquery";
import {AjaxForms} from "../../../forms/ajaxForms";

export class DocumentsTabLoader {

    constructor() {
        this.results = $('.js-documents');
        this.events();

        DocumentsTabLoader.addCustomerNameToButton();
    }

    static addCustomerNameToButton() {
        let name = $('#full_name').val();
        $('.js-customer_name').html(name);
    }

    events() {
        let self = this;

        $(document).off('click', '.js_create_new_invoice').on('click', '.js_create_new_invoice', function () {
            let button = $(this);
            self.loading(button);

            // Disable event - Fatura butonuna bir kez basılabilmeli.
            $(this).off('click');

            let input = $(this).parent().parent().find('input');
            let footerText = input.val();

            self.createInvoice(button, footerText);
        });

        $(document).off('click', '.reload-page').on('click', '.reload-page', function () {
            self.showDocuments();
        });

        $(document).off('click', '.create_documents').on('click', '.create_documents', function () {
            if (typeof $('input[name=invoice_no]') == 'undefined' || $('input[name=invoice_no]').val() == '') {
                alert('Fatura no boş olamaz.');
                return;
            }

            self.createDocuments($(this));
        });

        $(document).off('click', '.show_invoice').on('click', '.show_invoice', function () {
            let orderId = $(this).data('order_id');
            let vbt_ettn = $(this).data('vbt_ettn');
            let url = $(this).data('url');

            self.showInvoice(orderId, vbt_ettn, url);
        });

        $(document).off('click', '.js_create_invoice').on('click', '.js_create_invoice', function () {
            let button = $(this);

            let footerText = $('#invoice-footer-text').val();

            // Disable event - Fatura butonuna bir kez basılabilmeli.
            $(this).off('click');

            self.loading(button);
            self.createInvoice(button, footerText);
        });

        $(document).off('click', '.js_create_exporginsas_invoice').on('click', '.js_create_exporginsas_invoice', function () {
            let button = $(this);
            self.loading(button);

            // Disable event - Fatura butonuna bir kez basılabilmeli.
            $(this).off('click');

            let input = $(this).parent().parent().find('input');
            let footerText = input.val();

            self.createInvoice(button, footerText);
        });

        $(document).off('click', '.js_cancel_invoice').on('click', '.js_cancel_invoice', function () {
            let button = $(this);
            self.loading(button);
            self.cancelInvoice(button);
        });

        $(document).off('click', '.js_destroy_etd').on('click', '.js_destroy_etd', function () {
            self.destroyEtd($(this));
        });

        $(document).off('click', '#uploaded_documents .js-delete-document').on('click', '#uploaded_documents .js-delete-document', function () {
            self.deleteDocument($(this));
        });

        $(document).off('click', '.js-add-invoice-footer-text').on('click', '.js-add-invoice-footer-text', function () {
            let text = $(this).text();
            let footerText = $('#invoice-footer-text').val().split(',').filter(Boolean);
            footerText.push(text);
            $('#invoice-footer-text').val(footerText.join(', '));
        });
    }

    destroyEtd(button) {
        let self = this;
        let url = button.data('url');

        url = url.replace('###', '') + button.data('id');

        console.log(url)
        $.ajax({
            type: 'DELETE',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function () {

            button.parent().html(`
                <div class="text-center">
                    Deleted
                </div>
            `);

        }).fail(function (xhr) {

            self.showErrors(xhr);

        });
    }

    deleteDocument(button) {
        let self = this;

        let url = button.data('url');

        console.log(url);

        $.ajax({
            type: 'DELETE',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function () {

            button.parent().html(`
                <div class="text-center">
                    Deleted
                </div>
            `);

        }).fail(function (xhr) {

            self.showErrors(xhr);

        });
    }

    /**
     * Called on vendorModal, on tab changed
     */
    showDocuments() {
        let self = this;
        let url = $('#documents_container').data('update-url');

        self.loading();

        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {

            self.results.html(result.view);

            new AjaxForms();

        }).fail(function (xhr) {

            self.showErrors(xhr);

        });
    }

    createDocuments(button) {

        let self = this;

        let defaultText = self.loading(button);

        $.ajax({
            type: 'PUT',
            url: $('#documents_container').data('create-url'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {

            if(result.length > 0 ) {
                alert(result.join(','));
            }

            self.showDocuments()

            button.html('Documents Created');

            setTimeout(function () {
                button.html(defaultText);
            }, 2000);

        }).fail(function (xhr) {
            self.showErrors(xhr);
        });
    }

    /**
     * Create Invoice Part
     */
    createInvoice(button, footerText) {

        let self = this;

        $.ajax({
            type: 'PUT',
            url: button.data('create-invoice-url'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                footerText: footerText,
            },
        }).done(function (result) {
            self.showDocuments();

            button.html('Invoice created');

        }).fail(function (xhr) {
            self.showErrors(xhr);
        });
    }

    /**
     * Cancel Invoice Part
     */
    cancelInvoice(button) {
        let self = this;

        self.loading(button);

        $.ajax({
            type: 'POST',
            url: button.data('cancel-invoice-url'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                type: button.data('type')
            },
        }).done(function () {

            self.showDocuments();

        }).fail(function (xhr) {
            self.showErrors(xhr);
        });
    }

    /**
     * Show Invoice Part
     */
    showInvoice(orderId, vbt_ettn, url) {

        let self = this;

        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                orderId: orderId,
                vbt_ettn: vbt_ettn
            },
        }).done(function (result) {
            if (result.Data.InvoiceHtmlView != undefined) {
                $('#create_invoice').html(result.Data.InvoiceHtmlView);
            }
        }).fail(function (xhr) {
            self.showErrors(xhr);
        });
    }

    loading(button = null) {

        this.resetErrors();

        let defaultText = '';

        if (button != null) {
            button.html(` <i class="fas fa-spinner fa-pulse"></i> Processing..`);
            defaultText = button.html();
        }

        this.results.html(`
            <div class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        `);

        return defaultText;
    }

    resetErrors() {
        $('.js-document-errors').text('').addClass('d-none');
    }

    showErrors(xhr) {

        let errorList = [];
        if (xhr.responseJSON.message !== undefined) {
            errorList.push(xhr.responseJSON.message);
        }
        if (xhr.responseJSON.error === undefined) {
            errorList.push(xhr.responseJSON.error);
        }

        alert(errorList);
        console.log(errorList);

        // Tüm sayfa ajaxa çevrilince boşa düştü
        //$('.js-document-errors').text(errorList.join(' : ')).removeClass('d-none');
    }
}
