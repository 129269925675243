import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";

/**
 * let form = new AddressForm();
 *
 * // result ajax sonucu
 * form.createAddress(result);
 *
 * old : receiverAddressForm.js
 */
export class AddressForm {

    constructor(targetDiv, targetInput) {
        this.targetDiv = $(targetDiv);
        this.targetInput = $('input[name=' + targetInput + ']');
    }

    /**
     * Creates address facade, and fill form
     * @param result
     *      'address': data.address,
     *      'email': data.address.address_mail[0].mail,
     *      'phone': data.address.address_phone[0].phone,
     *      'code': data.address.address_phone[0].code,
     *      'location': data.location
     */
    fill(result) {
        $('.js_address_go_back').trigger('click');
        $('.js-order_update_address').removeClass('d-none');

        this.fillForm(result);
    }

    selectIntlPhoneCode(code) {
        try {
            const input = document.querySelector('.js-int-phone');
            const iti = window.intlTelInputGlobals.getInstance(input);
            iti.setCountry(code);
        } catch (e) {

        }
    }

    fillForm(result) {
        $('#address #full_name').val(result.address.full_name);
        $('#address #company_name').val(result.address.company_name);
        $('#address #phone').val(result.phone);
        $('#address #address').val(result.address.address);
        $('#address #email').val(result.email);
        $('#address #postal_code').val(result.address.postal_code);
        $('#address #phone_code').val(result.code);

        this.selectIntlPhoneCode(result.phone_country_code);

        let countryOption = new Option(result.location.country_name, result.location.country_id, false, true);
        $('select[name=country_id]').append(countryOption).trigger('change');

        let stateOption = new Option(result.location.state_name, result.location.state_id, false, true);
        $('select[name=state_id]').append(stateOption).trigger('change');

        let cityOption = new Option(result.location.city_name, result.location.city_id, false, true);
        $('select[name=city_id]').append(cityOption).trigger('change');
    }

    selectBillTo(isBillTo) {
        const checkbox = document.getElementById('is_bill_to_address');
        if (isBillTo) {
            lcs_on(checkbox);
        } else {
            lcs_off(checkbox);
        }
    }

    resetForm() {
        this.selectIntlPhoneCode('');

        $('#address #full_name').val('');
        $('#address #company_name').val('');
        $('#address #phone').val('');
        $('#address #address').val('');
        $('#address #email').val('');
        $('#address #postal_code').val('');

        $('select[name=country_id]').val('').trigger('change');
        $('select[name=state_id]').val('').trigger('change');
        $('select[name=city_id]').val('').trigger('change');
    }

    reset() {
        this.resetForm();

        $('.js-order_update_address').addClass('d-none');

        this.resetFormErrors();
    }

    resetFormErrors() {
        let errors = new formErrors();
        errors.resetFormStyle();
    }
}
