import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";
import {AddressForm} from "./addressForm";
import {CustomerAddressList} from "./customerAddressList";

export class AddressManager {

    constructor() {

        this.events();
        this.form = new AddressForm('#address_container', 'receiver_address_id');

        new CustomerAddressList(this);

        this.loadReceiverAddress();
    }

    events() {

        let self = this;

        /**
         * Receiver address new
         */
        $('.js_order_add_receiver_address').on('click', function () {

            self.form.reset();
            self.form.selectBillTo(false);

            self.showAddressTab();

        });

        /**
         * Update Address
         */
        $('.js_order_update_receiver_address').on('click', function () {

            const addressId = document.querySelector('input[name="receiver_address_id"]');

            self.getAddress(addressId.value)
                .then(result => {
                    self.form.fillForm(result);
                    self.form.selectBillTo(false);
                    self.showAddressTab();
                })
                .catch(error => {
                    alert(error);
                });
        });

        /**
         * Bill to address new
         */
        $('.js_order_add_bill_to_address').on('click', function () {

            self.form.reset();
            self.form.selectBillTo(true);

            self.showAddressTab();
        });

        /**
         * Bill to address update
         */
        $('.js_order_update_bill_to_address').on('click', function () {

            const addressId = document.querySelector('input[name="bill_to_address_id"]');
            self.getAddress(addressId.value)
                .then(result => {
                    self.form.fillForm(result);
                    self.form.selectBillTo(true);
                    self.showAddressTab();
                })
                .catch(error => {
                    alert(error);
                });
        });

        $('.js_address_go_back').on('click', function () {
            self.showMainTab();
        });

        /**
         * SAVE
         */
        $('.js_save_address').on('click', function () {
            self.saveAddress()
                .then(result => {
                    const addressId = result.id;
                    if (result.is_bill_to_address == '1') {
                        $('#bill_to_address_id').val(addressId);
                    } else {
                        $('#receiver_address_id').val(addressId);

                        // Adres kutusunu doldur.
                        let content = self.showAddressFacade(result);

                        $('#address_container').html(content);
                    }

                    self.showMainTab();
                })
                .catch(error => {
                    alert(error);
                });
            ;

        });
    }

    showMainTab() {
        $('#form_order #order-tab-header').show();

        this.closeAllTabs();

        $('#form_order .tab-content #home').addClass('show active');

        $('#modal_theme_primary .modal-footer').show();
    }

    showAddressTab() {
        this.form.resetFormErrors();
        this.closeAllTabs();

        $('#form_order #order-tab-header').hide();

        $('#form_order .tab-content #address').addClass('show active');
    }

    loadReceiverAddress() {
        let self = this;

        if ($('#receiver_address_id').val() > 0) {
            let addressId = $('#receiver_address_id').val();

            this.getAddress(addressId)
                .then(result => {
                    let content = self.showAddressFacade(result);
                    $('#address_container').html(content);
                })
                .catch(error => {
                    alert(error);
                });
        }
    }

    showAddressFacade(result) {
        let content = $('.address_template').html();
        content = content.replace(/__FULL_NAME__/g, result.address.full_name);
        content = content.replace(/__COMPANY_NAME__/g, result.address.full_name);
        content = content.replace(/__EMAIL__/g, result.email);
        content = content.replace(/__CODE__/g, result.code);
        content = content.replace(/__PHONE__/g, result.phone);
        content = content.replace(/__ADDRESS__/g, result.address.address);
        content = content.replace(/__LOCATION__/g, result.location.text);
        content = content.replace(/__POSTAL_CODE__/g, result.address.postal_code);

        return content;
    }

    getAddress(addressId) {

        let self = this;

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'post',
                url: '/vendor-site/user/get-address/' + addressId,
                dataType: 'json',
                cache: false,
                crossDomain: true,
                data: {},
            }).done(function (result) {

                resolve(result);

            }).fail(function (xhr) {
                reject(xhr.responseJSON.error);
            });

        });
    }

    closeAllTabs() {
        $('#form_order .tab-content .tab-pane').removeClass('show active')
        $('#modal_theme_primary .modal-footer').hide();
    }

    saveAddress() {

        let self = this;

        let data = {
            order_id: self.sanitiseInt($('#form_order').data('order_id')),
            receiver_address_id: self.sanitiseInt($('input[name=receiver_address_id]').val()),
            full_name: $('input[name=full_name]').val(),
            company_name: $('input[name=company_name]').val(),
            phone: $('input[name=phone]').val(),
            email: $('input[name=email]').val(),
            address: $('textarea[name=address]').val(),
            postal_code: $('input[name=postal_code]').val(),
            country_id: self.sanitiseInt($('select[name=country_id]').val()),
            state_id: self.sanitiseInt($('select[name=state_id]').val()),
            city_id: self.sanitiseInt($('select[name=city_id]').val()),
            phone_code: $('#phone_code').val(),
            is_bill_to_address: $('#is_bill_to_address').prop('checked') ? 1 : 0,
        };

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'post',
                url: '/vendor-site/vendor-save-order-address',
                dataType: 'json',
                cache: false,
                crossDomain: true,
                data: data,
            }).done(function (result) {

                resolve(result);

            }).fail(function (xhr) {

                reject(xhr.responseJSON.error);
            });
        });
    }

    sanitiseInt(value) {
        try {
            let newValue = parseInt(value);
            if (isNaN(newValue)) {
                return '';
            }
            return newValue;
        } catch (e) {
            console.log(e);
        }
    }

    showErrors(status, jsonErrorList) {

        if (status == 403) {
            alert('This action is unauthorized.');
            return;
        }

        // Response may be in errors message bag
        if (typeof jsonErrorList.errors != 'undefined') {
            jsonErrorList = jsonErrorList.errors;
        }

        new formErrors(jsonErrorList, $('#form_order'));
    }
}

