import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;
        return [
            item.id,
            '<img src="' + item.img + '" class="img-fluid">',
            '<b>' + item.headline + '</b>',
            item.language,
            self.isActive(item.active),
            self.updateButton(data, item.id, 'adminBlog'),
            self.deleteButton(item),
        ];
    }
}
