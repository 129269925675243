import $ from "jquery";
import {AddressForm} from "./addressForm";

export class CustomerAddressList {

    constructor(addressManager) {
        this.addressManager = addressManager;
        let self = this;
        $('.js-select-reciever-address').each(function () {
            self.createSelect2($(this));
        });
    }

    createSelect2(item) {

        let self = this;

        let url = item.data('url');

        item.css({'width': '100%'}).select2({
            placeholder: 'Eski gönderi adresleri',
            allowClear: true,
            minimumInputLength: 3,
            dropdownParent: $('#address-dropdown-container'),
            ajax: {
                url: url,
                delay: 250,
                method: 'post',
                dataType: 'json',
                data: function (params) {
                    return {
                        search: params.term
                    };
                },
                processResults: function (data) {
                    return data;
                },

            },
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (data) {
                return data.html;
            },
            templateSelection: function (data) {
                return data.text;
            }
        });

        item.on('select2:select', function (e) {

            try {
                let result = e.params.data;
                $('#receiver_address_id').val(result.id);

                // Adres kutusunu doldur.
                let content = self.addressManager.showAddressFacade(result);
                $('#address_container').html(content);
            } catch (e) {
                alert(e.message)
            }

        });
    }
}
