 export class FormModalOnLoad {

    constructor() {
        this.events();
    }

    events() {
        let self = this;
        $('#js-clear-fedex-cache').on('click', function () {
            let url = $(this).data('url');
            self.deleteImage(url, $(this));
        });

        $('#js-shipping-token').on('click', function () {

            self.getToken($(this));
        });
    }

    getToken(button) {

        $.ajax({
            type: 'DELETE',
            url: 'admin/clear-tokens/' + button.data('id'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {

            // Oauth 2.0 yetkilendirme için gerekli (Joom etsy ve amazom da vardı.)
            if (typeof result.redirect != 'undefined') {
                window.location.href = result.redirect;
            }
            console.log(result)

        }).fail(function (xhr, textStatus, errorThrown) {
            alert('Error');
        });
    }

    deleteImage(url, button) {
        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {},
        }).done(function (result) {
            alert('Success');
        }).fail(function (xhr, textStatus, errorThrown) {
            alert('Error');
        });
    }
}
