import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super()

        let self = this;

        return [
            item.id,
            '<b>' + item.name + '</b>',
            self.isActive(item.is_active),
            item.parent_name ?? '<strong class="text-primary">Ana Kategori</strong>',
            self.updateButton(data, item.id),
            self.deleteButton(item),
        ];
    }
}
