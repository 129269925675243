import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super();

        let self = this;

        return [
            item.id,
            '<b>' + item.sku + '</b> ',
            item.code,
            item.product_code,
            item.gtip_code,
            self.updateButton(data, item.id, '')
        ];
    }
}
